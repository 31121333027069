import React from "react";

import Navigation from "../../navigation/navigation";
import imageOne from "../attorneyImages/pcook.jpg";
import "./philip-cook.css";
import { Link } from "react-router-dom";
import vcard from "./Philip_E_Cook.vcf";
import martindale_hubbell_logo from "./martindale_hubbell_logo.png";
import superlawyers_phil from "./superlawyers_phil.png";

export default class PhilipCook extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      philipCook: {
        name: `Philip E. Cook`,
        title: `Managing Attorney`,
        contact: `Direct: 213-988-6100`,
        email: ``,
        img: imageOne,
        other: [
          {
            type: `Education:`,
            content: `University of California, Los Angeles (J.D. 1990; Editor, Law Review); Pacific Christian College (B.A. Psychology, 1979)`
          },
          {
            type: `Admitted:`,
            content: `California, all U.S. District Courts in California, U.S. Court of Appeals for Ninth Circuit, and pro hac vice in courts in Indiana, Oregon, North Carolina, Texas, Washington and Hawaii`
          },
          {
            type: `Clerkship:`,
            content: `Law Clerk to Judge Consuelo B. Marshall, United States District Court, Central District of California (1991-92)`
          }
        ]
      }
    };
  }

  componentDidMount() {
    //scroll to top for react-router-dom
    window.scrollTo(0, 0);
  }

  render() {
    return (
      <div>
        <Navigation />
        <div className="container push-top-lg" id="philip-cook-bio">
          <div className="row">
            <div className="col-md-3">
              <div>
                <img
                  className="philip-cook-bio-img push-bottom-sm"
                  src={this.state.philipCook.img}
                  alt={this.state.philipCook.name}
                />
              </div>
              <p className="text">{this.state.philipCook.name}</p>
              <p className="text">{this.state.philipCook.title}</p>
              <p className="text">{this.state.philipCook.contact}</p>
              <a
                href={`mailto:${this.state.philipCook.email}?Subject=Cook%20Law%20Firm`}
                target="_top"
                className="mail-link philip-cook-link"
              >
                {this.state.philipCook.email}
              </a>
              {this.state.philipCook.other.map((eachOther, i) => (
                <div className="bio-spacer" key={i}>
                  <span className="bold-title">{eachOther.type} </span>
                  <p className="cook-bio-sidebar">{eachOther.content}</p>
                </div>
              ))}
              {/* <div className="push-top-sm" style={{ marginBottom: "20px" }}>
                <a
                  href="https://twitter.com/thecooklawfirm"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <span className="fa-stack">
                    <i className="fa fa-circle fa-stack-2x twitter" />
                    <i className="fa fa-twitter fa-stack-1x fa-inverse" />
                  </span>
                </a>
                <a
                  href="https://www.linkedin.com/in/philipecook/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <span className="fa-stack">
                    <i className="fa fa-circle fa-stack-2x linkedin" />
                    <i className="fa fa-linkedin fa-stack-1x fa-inverse" />
                  </span>
                </a>
                <a href={vcard} download>
                  <span className="fa-stack">
                    <i className="fa fa-circle fa-stack-2x vcard" />
                    <i className="fa fa-newspaper-o fa-stack-1x fa-inverse" />
                  </span>
                </a>
              </div> */}
              <a
                href="https://profiles.superlawyers.com/california/los-angeles/lawyer/philip-e-cook/d9ad77cd-d037-4915-90cc-1f18e023a470.html"
                target="_blank"
                rel="noopener noreferrer"
              >
                <img
                  src={superlawyers_phil}
                  alt="superlawyers"
                  height="100px"
                />
              </a>
              <a
                href="https://www.lawyers.com/los-angeles/california/philip-earl-cook-134906-a/?utm_source=ratingverification&utm_medium=referral"
                target="_blank"
                rel="noopener noreferrer"
              >
                <img
                  src={martindale_hubbell_logo}
                  alt="martindale_hubbell"
                  height="65px"
                />
              </a>
              {/* <!--Peer Ratings Widget--> */}
              <div
                style={{
                  marginRight: "15px",
                  marginBottom: "15px",
                  marginTop: "25px"
                }}
                id="mh-rating-container"
                isln="901535267"
                param="9340edaaca327c65d6944d4bd4018817"
              />
              {/* <!--Peer Ratings Widget--> */}
            </div>
            <div className="col-md-9">
              <div style={{ marginBottom: "15px" }}>
                Phil has tried cases before judges, juries, arbitrators and
                arbitration panels and argued appeals in both federal and state
                courts. Phil’s practice currently focuses on insurance recovery
                for policyholders and business-to-business disputes in a variety
                of contexts. His success in the courtroom covers the gamit of
                commercial litigation, including individual and class action
                cases involving contract disputes; corporate governance and
                securities and financial products claims; Business and
                Professions Code section 17200 claims and business torts; real
                estate, land use and environmental issues; product liability and
                toxic torts; antitrust and trade regulation; patent, trademark
                and copyright infringement; trade secrets; governmental claims
                and liabilities; freedom of speech and religious protection
                under the First Amendment; health care, construction defect, and
                wrongful termination, employment discrimination and executive
                compensation.
              </div>
              <div style={{ marginBottom: "15px" }}>
                Before law school, Phil spent 12 years in the computer and
                consumer electronics fields, with business experience ranging
                from marketing and management positions with national Fortune
                500 companies to his own entrepreneurial ventures.
              </div>
              <div style={{ marginBottom: "15px" }}>
                Phil is committed to <i>pro bono</i> work, having represented
                dozens of Public Counsel clients over the years. He has been an
                approved voluntary settlement officer for the California Court
                of Appeal, mediating appellate cases since 2004, was a
                court-appointed early neutral evaluator for the Los Angeles
                County Superior Court beginning in 2008, and has been a
                court-approved panel mediator for the United States District
                Court, Central District of California, since 2015.
              </div>
              <div style={{ marginBottom: "10px" }}>
                As an adjunct law professor, Phil has taught law school courses
                in trial practice. He has participated in seminars and workshops
                teaching legal research, deposition, advocacy, and trial
                techniques and has appeared on the faculty at local and national
                continuing legal education seminars.
              </div>

              <h4 className="header-text">Community and Bar Service</h4>

              <div style={{ marginBottom: "10px" }}>
                Phil serves on the Board of Directors of{" "}
                <a
                  className="philip-cook-link"
                  href="https://www.facebook.com/publiccounsel/  "
                  target="_blank"
                >
                  Public Counsel
                </a>
                , the nation’s largest pro bono law firm, and is a Past Chair
                and a member of its Executive Committee. He is also a member of
                the Executive Committee of the Litigation Section of the Los
                Angeles County Bar Association, where he has co-chaired its
                Complex Court Committee, as well as its Complex Court Symposium
                in November 2014 and 2016. Phil is a Past President of the Los
                Angeles Chapter of the{" "}
                <a
                  className="philip-cook-link"
                  href="https://abtl.org/"
                  target="_blank"
                >
                  Association of Business Trial Lawyers
                </a>{" "}
                (2012-13), where he served on its Board of Governors for nine
                years.
              </div>

              <h4 className="header-text">Honors and Recognition</h4>

              <div>
                Phil has been recognized by ALM Media and Martindale-Hubbell as
                a Top Rated Litigator, by Chambers & Partners in Chambers USA:
                America’s Leading Lawyers; and by US News & World Report and The
                Best Lawyers in America for Commercial Litigation and
                Litigation–Securities. Since 2004 he has been regularly listed
                as a Southern California “Super Lawyer,” and he is AV Peer Rated
                by Martindale-Hubbell. Phil has received Public Counsel’s
                Volunteer of the Year award in 2012 (representing applicants for
                child care centers and homes), 2013 (representing victims of
                mortgage and other consumer fraud), 2017 (representing operators
                of child care homes), and 2021 (representing a Ph.D. candidate
                with claims against the university).
              </div>

              {/* insurance_experience_highlights/philip_cook */}
              {/* securities_corporate_governance/philip_cook */}
              {/* other_experience_highlights/philip_cook */}
              <div className="push-bottom-lg">
                <Link to="../Attorneys" className="Attorneys-Links">
                  <h2
                    className="philip-cook-bio-h2-links"
                    style={{ marginTop: "15px" }}
                  >
                    [Back to Attorneys]
                  </h2>
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
